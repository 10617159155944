import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import downLoadIcon from "../../assets/icons/download.svg";
import moreIcon from "../../assets/icons/Icon_views_more.svg";
import rightArrowIcon from "../../assets/icons/icon_views_right.svg";
import emotionalIcon from "../../assets/icons/icon_views_emotional.svg";
import trendsIcon from "../../assets/icons/icon_views_trends.svg";
import conversionIcon from "../../assets/icons/icon_views_conversion.svg";
import equityIcon from "../../assets/icons/icon_views_equity.svg";
import advertisingIcon from "../../assets/icons/icon_views_advertising.svg";
import profileIcon from "../../assets/icons/icon_views_profile.svg";
import differentiationIcon from "../../assets/icons/icon_views_differentiation.svg";
import perceptualIcon from "../../assets/icons/icon_views_perceptual.svg";
import butterflyIcon from "../../assets/icons/icon_views_butterfly.svg";
import prepostIcon from "../../assets/icons/icon_views_prepost.svg";
import fileIcon from "../../assets/icons/File type icon.png";
import editIcon from "../../assets/icons/icon_report_edit.svg";
import copIcon from "../../assets/icons/icon_views_clone.svg";
import exportIcon from "../../assets/icons/icon_views_download.svg";
import xlsxIcon from "../../assets/icons/xlsx.svg";
import pptIcon from "../../assets/icons/ppt.svg";
import deleteIcon from "../../assets/icons/icon_views_delete.svg";
import searchIcon from "../../assets/icons/icon-search.svg";
import { RxCrossCircled } from "react-icons/rx";
import "./Views.scss";
import CreateEditReport from "./CreateEditReport";
import DeleteReport from "./DeleteViewOrReport";
import EditViewDateSelector from "./EditViewDateSelector";
import cx from "classnames";
import ApiData from "../../utils/Api";
import {
  setReports,
  setViews,
} from "../../reducers/TwoByTwoChart/actionCreators";
import LoadingLayer from "../widgets/Shared/LoadingLayer";
import { generateWSPrivateChannelId } from "../../utils/userHelper";
import useFileDownload from "../FileDownload/hooks/useFileDownloadHook";
import Toaster from "./Toaster";
import useViewsReportsExportsStatus from "../../hooks/useViewsReportsExportsStatus";
import ViewCardTooltip from "./ViewCard/ViewCardTooltip";

const screenLogos = [
  { screenType: "EQUITY", logo: equityIcon, color: "#7f56d9" },
  { screenType: "CONVERSION", logo: conversionIcon, color: "#17B26A" },
  { screenType: "EMOTIONAL", logo: emotionalIcon, color: "#FF9500" },
  { screenType: "ADVERTISING", logo: advertisingIcon, color: "#ff2d55" },
  { screenType: "TRENDS", logo: trendsIcon, color: "#30B0C7" },
  { screenType: "PROFILE", logo: profileIcon, color: "#493971" },
  { screenType: "DIFFERENTIATION", logo: differentiationIcon, color: "#938f96" },
  { screenType: "PERCEPTUAL", logo: perceptualIcon, color: "#89475e" },
  { screenType: "BUTTERFLY", logo: butterflyIcon, color: "#797677" },
  { screenType: "PREPOST", logo: prepostIcon, color: "#6750A4" },
];

const ReportsListComponent = (props) => {
  const dispatch = useDispatch();
  const reportsListData = useSelector(
    (state) => state?.twoByTwoChart?.reportsList
  );
  const viewsListData = useSelector((state) => state?.twoByTwoChart?.viewsList);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(null); // Main actions modal state
  const [isExportsModalOpen, setIsExportsModalOpen] = useState(null); // Export modal state for downLoadIcon
  const [isExportReportOpen, setIsExportReportOpen] = useState(null); // Child modal for Export Report button
  const [searchInput, setSearchInput] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [toasterMessage, setToasterMessage] = useState(null);
  const [selectedReports, setSelectedReports] = useState([]);
  const { addNewFileToDownload, updateNewFileDownloadStatus } =
    useFileDownload();
  const actionsModalRef = useRef();
  const exportsActionsRef = useRef();
  const clickedInsideModalRef = useRef(false); // Track clicks inside modal or icon
  const { id } = JSON.parse(localStorage.getItem("userInfo"));
  const connectionId = generateWSPrivateChannelId(id);
  const {
    isReportsExportAvailable,
    isReportsExcelExportAvailable,
    isReportsPPTExportAvailable,
  } = useViewsReportsExportsStatus();

  useEffect(() => {
    setCategoryListData(reportsListData);
    updateViewsForValidation(reportsListData);
  }, [reportsListData]);

  // Fetch initial data
  useEffect(() => {
    getReportsListData();
  }, []);

  const updateViewsForValidation = async (reportsListData) => {
    if (reportsListData?.length === 0) {
      let data = await ApiData.getAllReportViews();
      dispatch(setViews(data));
    }
  };

  const getReportsListData = async () => {
    let reportsData = await ApiData.getReports();
    updateViewsForValidation(reportsData);
    dispatch(setReports([...reportsData]));
    setCategoryListData(reportsData);
    setIsLoading(false);
  };

  // Close modal when clicking outside
  const handleClickOutside = (e) => {
    // Check if the click happened inside the modal or any action-triggering element
    if (
      !actionsModalRef.current?.contains(e.target) &&
      !exportsActionsRef.current?.contains(e.target)
    ) {
      setIsOpen(null); // Close main modal
      setIsExportsModalOpen(null); // Close export modal
      setIsExportReportOpen(null); // Close export report modal
    }
  };

  // Attach and detach click listener for closing modals
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle main modal visibility
  const handleReportActionsModal = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
    setIsOpen((prev) => (prev === id ? null : id));
    setIsExportsModalOpen(null);
    setIsExportReportOpen(null);
  };

  // Toggle export modal visibility
  const handleExportModal = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
    setIsExportsModalOpen((prev) => (prev === id ? null : id));
    setIsOpen(null);
    setIsExportReportOpen(null);
  };

  // Toggle export report options for Export Report button
  const handleExportReportOptions = (id, e) => {
    e.stopPropagation();
    clickedInsideModalRef.current = true;
    setIsExportReportOpen((prev) => (prev === id ? null : id));
    setIsExportsModalOpen(null);
  };

  // Handle actions (edit, copy, delete)
  const handleReportsActions = (action, ele) => {
    setSelectedAction(action ? action : null);
    setSelectedReports(ele ? [ele] : []);
    setIsOpen(null); // Close main modal
    setIsExportsModalOpen(null);
    setIsExportReportOpen(null);
  };

  // Handle search input change
  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value.trim() !== "") {
      handleKeyUp("", e.target.value);
    } else {
      setCategoryListData(reportsListData); // Reset data
    }
  };

  // Clear search
  const clearSearch = () => {
    setSearchInput("");
    setCategoryListData(reportsListData); // Reset data
  };

  // Search on "Enter" key press
  const handleKeyUp = (e, val) => {
    let searchValue = val ? val : searchInput;
    if (e?.keyCode === 13 || searchValue.trim() !== "") {
      const filteredData = categoryListData?.filter(({ name }) =>
        name
          .toLowerCase()
          ?.replace(/\s/g, "")
          .includes(searchValue.toLowerCase()?.replace(/\s/g, ""))
      );
      setCategoryListData(filteredData?.length ? filteredData : []);
    }
  };

  const handleRedirectViewsPage = (selectedReport) => {
    props.data.dataItemClick(1, `/reports/${selectedReport?.id}`, selectedReport?.name);
  };

  function handleToasterClose(e) {
    e && e.stopPropagation();
    setToasterMessage(null);
  }

  function handleExport(ele, isPPT = false) {
    const fileType = isPPT ? "pptx" : "xlsx";
    const fileName = `${ele?.name}.${fileType}`;
    ApiData.PostReportExport(
      {
        reportId: ele?.id,
        fileName,
        connectionId,
        userId: id,
      },
      fileType
    )
      .then((res) => {
        handleToasterMessage({
          type: "success",
          message: "Export initiated",
        });
        // updateNewFileDownloadStatus(true);
        addNewFileToDownload({
          fileName,
          jobId: res,
          screenType: "reports",
        });
      })
      .catch(() => {
        handleToasterMessage({
          type: "error",
          message: "Error while exporting report.",
        });
      });
  }

  const handleToasterMessage = (messageObj) => {
    setToasterMessage({
      type: messageObj.type,
      message: messageObj.message,
    });
  };

  const renderExports = (ele) => {
    return (
      <div className="viewsActionsContainer">
        {isReportsPPTExportAvailable && (
          <li
            className="viewsButtonContainer"
            onClick={handleExport.bind({}, ele, true)}
          >
            <img src={pptIcon} className="viewButtonIcon" alt="" />
            <div className="buttonText">PPT</div>
          </li>
        )}
        {isReportsExcelExportAvailable && (
          <li
            className="viewsButtonContainer"
            onClick={handleExport.bind({}, ele, false)}
          >
            <img src={xlsxIcon} className="viewButtonIcon" alt="" />
            <div className="buttonText">Excel</div>
          </li>
        )}
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <LoadingLayer />
      ) : (
        <div className="viewsContainer reportsPage">
          {reportsListData?.filter(({ viewIds }) => viewIds !== null).length >
          0 ? (
            <>
              <div className="selectFilterContainer">
                <img src={searchIcon} alt="" className="actionIcon" />
                <input
                  type="text"
                  placeholder="Search Reports"
                  value={searchInput}
                  className="textarea"
                  onChange={onSearchChange}
                  onKeyUp={handleKeyUp}
                />
                {searchInput && (
                  <RxCrossCircled
                    className="prefixIcon cursor"
                    onClick={clearSearch}
                  />
                )}
              </div>
              {categoryListData?.length > 0 ? (
                categoryListData.map(
                  (ele, index) =>
                    ele.viewIds !== null && (
                      <div className="viewCard" key={index}>
                        <div className="viewContentContainer">
                          <div className="cardContentContainer">
                            <div className="viewHeaderContainer">
                              <ViewCardTooltip
                                contentList={[ele?.name]}
                                offset={[0, 5]}
                                delay={500}
                              >
                                <div className="header">{ele?.name}</div>
                              </ViewCardTooltip>
                              {ele.description && (
                                <ViewCardTooltip
                                  contentList={[ele?.description]}
                                  offset={[0, 5]}
                                  delay={500}
                                >
                                  <div className="subHeader">
                                    {ele.description}
                                  </div>
                                </ViewCardTooltip>
                              )}
                            </div>
                            <div className="screensIconsContainer">
                              {ele.viewScreenTypes?.map((screen, ind) => {
                                let logoObj = screenLogos?.find(
                                  ({ screenType }) => screen === screenType
                                );

                                const brandStyle = {
                                  zIndex: ind + 1,
                                  left: 25 * ind + "px",
                                  background:
                                    ind === 4 ? "#f2f4f7" : logoObj?.color,
                                  border:
                                    ind === 4
                                      ? "2px solid #00000014"
                                      : "1.5px solid #FFFFFF",
                                };

                                return (
                                  <>
                                    {ind <= 4 && (
                                      <div
                                        className="avatarIconContainer"
                                        style={brandStyle}
                                        key={ind}
                                      >
                                        {ind < 4 && (
                                          <img src={logoObj?.logo} alt="" />
                                        )}
                                        {ind === 4 && (
                                          <div className="avatarIcon avatarIconNumber">
                                            +{ele?.viewScreenTypes?.length - 4}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="actionsCardContainer">
                            {/* Export Button */}
                            <div className="actionIconsContainer">
                              {isReportsExportAvailable && (
                                <div className="logoContainer">
                                  <img
                                    src={downLoadIcon}
                                    alt=""
                                    className="actionIcons"
                                    onClick={(e) =>
                                      handleExportModal(ele.id, e)
                                    } // Open export modal
                                  />
                                </div>
                              )}
                              {isExportsModalOpen === ele.id &&
                                isReportsExportAvailable && (
                                  <ul
                                    ref={exportsActionsRef}
                                    className="listContainer exportActionDropdown"
                                    style={{ right: "110px" }}
                                  >
                                    {renderExports(ele)}
                                  </ul>
                                )}
                            </div>
                            {/* Actions (More Options) */}
                            {(isReportsExportAvailable ||
                              ele.type !== "SYSTEM") && (
                              <div className="actionIconsContainer">
                                <div className="logoContainer">
                                  <img
                                    src={moreIcon}
                                    alt=""
                                    className="actionIcons"
                                    onClick={(e) =>
                                      handleReportActionsModal(ele.id, e)
                                    }
                                  />
                                </div>
                                {isOpen === ele.id && (
                                  <ul
                                    ref={actionsModalRef}
                                    className="listContainer"
                                  >
                                    <div className="viewsActionsContainer">
                                      {ele.type !== "SYSTEM" && (
                                        <>
                                          <li
                                            className={cx(
                                              "viewsButtonContainer",
                                              {
                                                disabled: ele.type === "SYSTEM",
                                              }
                                            )}
                                            onClick={() =>
                                              handleReportsActions("edit", ele)
                                            }
                                          >
                                            <img
                                              src={editIcon}
                                              className="viewButtonIcon"
                                              alt=""
                                            />
                                            <div className="buttonText">
                                              Edit Report
                                            </div>
                                          </li>
                                          <li
                                            className={cx(
                                              "viewsButtonContainer",
                                              {
                                                disabled: ele.type === "SYSTEM",
                                              }
                                            )}
                                            onClick={() =>
                                              handleReportsActions(
                                                "edit-date-period",
                                                ele
                                              )
                                            }
                                          >
                                            <img
                                              src={editIcon}
                                              alt=""
                                              className="viewButtonIcon"
                                            />
                                            <div className="buttonText">
                                              Edit Date Period
                                            </div>
                                          </li>
                                          <li
                                            className={cx(
                                              "viewsButtonContainer",
                                              {
                                                disabled: ele.type === "SYSTEM",
                                              }
                                            )}
                                            onClick={() =>
                                              handleReportsActions("clone", ele)
                                            }
                                          >
                                            <img
                                              src={copIcon}
                                              alt=""
                                              className="viewButtonIcon"
                                            />
                                            <div className="buttonText">
                                              Clone Report
                                            </div>
                                          </li>
                                        </>
                                      )}
                                      {isReportsExportAvailable && (
                                        <li
                                          className="viewsButtonContainer"
                                          onClick={(e) =>
                                            handleExportReportOptions(ele.id, e)
                                          }
                                        >
                                          <img
                                            src={exportIcon}
                                            alt=""
                                            className="viewButtonIcon"
                                          />
                                          <div className="buttonText">
                                            Export Report
                                          </div>
                                          {isExportReportOpen === ele.id && (
                                            <ul
                                              ref={exportsActionsRef}
                                              className="listContainer exportActionDropdown"
                                              style={{
                                                right: "-22px",
                                                top: "33px",
                                              }}
                                            >
                                              {renderExports(ele)}
                                            </ul>
                                          )}
                                        </li>
                                      )}
                                    </div>
                                    <div className="divider"></div>
                                    {ele.type !== "SYSTEM" && (
                                      <div className="viewsActionsContainer">
                                        <li
                                          className={cx(
                                            "viewsButtonContainer deleteButtonContainer",
                                            { disabled: ele.type === "SYSTEM" }
                                          )}
                                          onClick={() =>
                                            handleReportsActions("delete", ele)
                                          }
                                        >
                                          <img
                                            src={deleteIcon}
                                            className="viewButtonIcon"
                                            alt=""
                                          />
                                          <div className="buttonText">
                                            Delete Report
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </ul>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="viewLinkContainer"
                          onClick={() => {
                            dispatch(setViews([]));
                            handleRedirectViewsPage(ele);
                          }}
                        >
                          <div className="viewLinkButton">
                            <div className="viewLinkContent">View Report</div>
                            <img
                              src={rightArrowIcon}
                              className="viewLinkIcon"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="emptyCardContainer">
                  <div className="emptyImageContainer">
                    <div className="emptyCard">
                      <div
                        className="emptyFileImageConatiner"
                        style={{ height: "105px" }}
                      >
                        <img src={fileIcon} alt="" />
                      </div>
                      <div className="emptyContentContainer">
                        <div className="subHeader">
                          No Reports found related to search.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="emptyCardContainer">
              <div className="emptyImageContainer">
                <div className="emptyCard">
                  <div className="emptyFileImageConatiner">
                    <img src={fileIcon} alt="" />
                  </div>
                  <div className="emptyContentContainer">
                    <div className="header">No Reports Created</div>
                    <div className="subHeader">
                      You must have already saved slides in order to create a
                      report.
                    </div>
                  </div>
                  <div
                    className="form-button-actions"
                    onClick={() =>
                      viewsListData?.length !== 0 &&
                      handleReportsActions("create")
                    }
                  >
                    <div
                      className={cx("saveButton", {
                        disabled: viewsListData?.length === 0,
                      })}
                    >
                      Build Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Modals for Create/Edit/Delete */}
      {(selectedAction === "clone" ||
        selectedAction === "edit" ||
        selectedAction === "create") && (
        <CreateEditReport
          isOpen={selectedAction !== null}
          selectedAction={selectedAction}
          handleReportsActions={handleReportsActions}
          closeModal={handleReportsActions}
          reportId={selectedReports[0]?.id}
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {selectedAction === "delete" && (
        <DeleteReport
          selectedType={"report"}
          closeModal={handleReportsActions}
          isOpen={selectedAction === "delete"}
          selectedData={selectedReports}
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {selectedAction === "edit-date-period" && (
        <EditViewDateSelector
          isOpen={selectedAction !== null}
          selectedViewAction="edit"
          handleReportsActions={handleReportsActions}
          closeModal={handleReportsActions}
          data={[selectedReports[0]]}
          selectedScreen='reports'
          handleToasterMessage={handleToasterMessage}
        />
      )}
      {toasterMessage && (
        <Toaster
          isOpen={toasterMessage}
          variant={toasterMessage?.type}
          onClose={(e)=>handleToasterClose(e)}
        >
          {toasterMessage?.message}
        </Toaster>
      )}
    </>
  );
};

export default ReportsListComponent;
