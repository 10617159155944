import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Views.scss";
import cx from "classnames";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import addIcon from "../../assets/icons/icon_views_add.svg";
import searchIcon from "../../assets/icons/icon_modal-search.svg";
import periodTypeIcon from "../../assets/icons/icon_views-period.svg";
import { RiCloseLine } from "react-icons/ri";
import { RxCrossCircled } from "react-icons/rx";
import ApiData from "../../utils/Api";
import { DateTime } from "luxon";
import FormSelect from "../Form/FormSelect";
import initData from "../../utils/initData";
import DateRangePicker from "./DateRangePicker";
import { setViews } from "../../reducers/TwoByTwoChart/actionCreators";

const EditViewDateSelector = (props) => {
  let {
    isOpen,
    closeModal,
    selectedViewIds,
    handleToasterMessage,
    handleViewIds = () => {},
    data,
    selectedScreen,
  } = props;
  const screenIcons = initData.screenIcons();
  const periodTypes = initData.getPeriodOptions();
  const periodData = initData.getPeriodOptions()?.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const viewsListData = useSelector((state) => state?.twoByTwoChart?.viewsList);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    primaryStartDate: selectedScreen === 'reports'?null: DateTime.now()
    .minus({ days: 180 })
    .toFormat("MM/dd/yyyy"),
    primaryEndDate: selectedScreen === 'reports'?null:DateTime.now().minus({ days: 1 }).toFormat("MM/dd/yyyy"),
    primaryPeriodType: selectedScreen === 'reports' ?1: 6,
    secondaryStartDate: DateTime.now()
      .minus({ days: 180 })
      .toFormat("MM/dd/yyyy"),
    secondaryEndDate: DateTime.now().minus({ days: 1 }).toFormat("MM/dd/yyyy"),
    secondaryPeriodType: 6,
    searchViewsStartDate: null,
    searchViewsEndDate: null,
    searchViewsPeriodType: 1,
    isDynamicDateRange: false,
    matchedViewsList: [],
    viewsSearchList: [],
    searchView: "",
    selectedViewIdsList: Array.isArray(selectedViewIds) ? selectedViewIds : [],
  });
  const modalRef = useRef(null);

  const handlecheckbox = (key) => {
    let { isDynamicDateRange } = inputValues;
    setInputValues({
      ...inputValues,
      isDynamicDateRange: !isDynamicDateRange,
    });
  };

  const handleEditViews = async () => {
    try {
      let { selectedViewIdsList } = inputValues;
      setIsLoading(true);
      let payload = {
        isDynamicDateRange: isDynamicDateRange,
        viewIds: [...selectedViewIdsList],
        reportIds: 
          data[0]?.id ? [data[0]?.id] :
              [data[0]?.reportId
                ? data[0].reportId
                : data[0].viewReport?.id,
            ],
        primaryStartDate: DateTime.fromFormat(
          primaryStartDate,
          "MM/dd/yyyy"
        ).toFormat("yyyy-MM-dd"),
        primaryEndDate: DateTime.fromFormat(
          primaryEndDate,
          "MM/dd/yyyy"
        ).toFormat("yyyy-MM-dd"),
        secondaryStartDate: DateTime.fromFormat(
          secondaryStartDate,
          "MM/dd/yyyy"
        ).toFormat("yyyy-MM-dd"),
        secondaryEndDate: DateTime.fromFormat(
          secondaryEndDate,
          "MM/dd/yyyy"
        ).toFormat("yyyy-MM-dd"),
        searchViewsStartDate:
          selectedScreen === "reports"
            ? DateTime.fromFormat(searchViewsStartDate, "MM/dd/yyyy").toFormat(
                "yyyy-MM-dd"
              )
            : null,
        searchViewsEndDate:
          selectedScreen === "reports"
            ? DateTime.fromFormat(searchViewsEndDate, "MM/dd/yyyy").toFormat(
                "yyyy-MM-dd"
              )
            : null,
        primaryperiodType:
          isDynamicDateRange === true
            ? "DYNAMIC_DATE_RANGE"
            : initData
                .getPeriodOptions()
                ?.find(({ id }) => id === primaryPeriodType)
                ?.name?.replace(/ /g, "_")
                ?.toUpperCase(),
        secondaryPeriodType: initData
          .getPeriodOptions()
          ?.find(({ id }) => id === secondaryPeriodType)
          ?.name?.replace(/ /g, "_")
          ?.toUpperCase(),
        searchViewsPeriodType:
          selectedScreen === "reports"
            ? initData
                .getPeriodOptions()
                ?.find(({ id }) => id === searchViewsPeriodType)
                ?.name?.replace(/ /g, "_")
                ?.toUpperCase()
            : null,
      };

      let response = await ApiData.updateViewsDates(payload);
      dispatch(setViews([...response]));
      handleViewIds();
      handleToasterMessage({
        type: "success",
        message: "Views have been succesfully updated.",
      });

      closeModal();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectPeriod = async (key, value) => {
    let startdate = DateTime.now().minus({ days: 180 }).toFormat("MM/dd/yyyy");
    let enddate = DateTime.now().minus({ days: 1 }).toFormat("MM/dd/yyyy");
    switch (value) {
      case 1:
        startdate = DateTime.now().minus({ days: 180 }).toFormat("MM/dd/yyyy");
        break;
      case 2:
        startdate = DateTime.now().minus({ days: 6 }).toFormat("MM/dd/yyyy");
        break;
      case 3:
        startdate = DateTime.now().minus({ days: 30 }).toFormat("MM/dd/yyyy");
        break;
      case 4:
        startdate = DateTime.now().minus({ days: 90 }).toFormat("MM/dd/yyyy");
        break;
      case 5:
        const currentDate = DateTime.now();
        const startingDateOfYear = currentDate.startOf("year");
        startdate = startingDateOfYear.toFormat("MM/dd/yyyy");
        break;
      case 6:
        startdate = DateTime.now().minus({ days: 180 }).toFormat("MM/dd/yyyy");
        break;
      default:
        break;
    }
    if (key === "primaryPeriodType") {
      setInputValues({
        ...inputValues,
        primaryStartDate: startdate,
        primaryEndDate: enddate,
        primaryPeriodType: value,
      });
    } else if (key === "secondaryPeriodType") {
      setInputValues({
        ...inputValues,
        secondaryStartDate: startdate,
        secondaryEndDate: enddate,
        secondaryPeriodType: value,
      });
    } else {
      let viewsData = await getDateMatchedViewsData({
        searchViewsStartDate: startdate,
        searchViewsEndDate: enddate,
        searchViewsPeriodType: value,
      });
      viewsData = viewsData?.map((obj) => {
        obj.isSelected = true;
        return obj;
      });

      setInputValues({
        ...inputValues,
        searchViewsStartDate: startdate,
        searchViewsEndDate: enddate,
        searchViewsPeriodType: value,
        selectedViewIdsList:
          viewsData?.length > 0 ? viewsData?.map((ele) => ele.viewId) : [],
        matchedViewsList: viewsData?.length > 0 ? viewsData : [],
        viewsSearchList: viewsData?.length > 0 ? viewsData : [],
      });
    }
  };

  const getDateMatchedViewsData = async (obj) => {
    try {
      let { searchViewsStartDate, searchViewsEndDate, searchViewsPeriodType } =
        obj;
      if (
        searchViewsStartDate !== null &&
        searchViewsEndDate !== null &&
        searchViewsPeriodType
      ) {
        let payload = {
          reportIds: [data[0]?.id],
          primaryStartDate: DateTime.fromFormat(
            searchViewsStartDate,
            "MM/dd/yyyy"
          ).toFormat("yyyy-MM-dd"),
          primaryEndDate: DateTime.fromFormat(
            searchViewsEndDate,
            "MM/dd/yyyy"
          ).toFormat("yyyy-MM-dd"),
          primaryperiodType: initData
            .getPeriodOptions()
            ?.find(({ id }) => id === searchViewsPeriodType)
            ?.name?.replace(/ /g, "_")
            ?.toUpperCase(),
        };
        let response = await ApiData.getDateMatchedViews(payload);
        return response?.views;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeCustomDateRanges = async (startDate, endDate, key) => {
    if (key === "primaryPeriodType") {
      setInputValues({
        ...inputValues,
        primaryStartDate: startDate,
        primaryEndDate: endDate,
      });
    } else if (key === "secondaryPeriodType") {
      setInputValues({
        ...inputValues,
        secondaryStartDate: startDate,
        secondaryEndDate: endDate,
      });
    } else {
      let viewsData = await getDateMatchedViewsData({
        searchViewsStartDate: startDate,
        searchViewsEndDate: endDate,
        searchViewsPeriodType,
      });
      viewsData = viewsData?.map((obj) => {
        obj.isSelected = true;
        return obj;
      });
      setInputValues({
        ...inputValues,
        searchViewsStartDate: startDate,
        searchViewsEndDate: endDate,
        selectedViewIdsList:
          viewsData?.length > 0 ? viewsData?.map((ele) => ele.viewId) : [],
        matchedViewsList: viewsData?.length > 0 ? viewsData : [],
        viewsSearchList: viewsData?.length > 0 ? viewsData : [],
      });
    }
  };

  const renderDatePicker = (
    key,
    periodType,
    startDate,
    endDate,
    isDisabled
  ) => {
    return (
      <>
        <div className="form-group">
          <div className="form-label-wrapper">
            <label htmlFor="reports" className="form-label view-label">
              Period Type
            </label>
          </div>
          <div className="form-input-wrapper view-input-wrapper dropdown">
            <img
              src={periodTypeIcon}
              alt=""
              width={15}
              height={15}
              style={{
                position: "absolute",
                zIndex: "1",
                top: "15px",
                left: "15px",
              }}
            />
            <FormSelect
              placeholder={"Select Period Type"}
              isClearable={true}
              options={periodData}
              selectedOption={periodData?.find(
                (ele) => ele.value === periodType
              )}
              isDisabled={isDisabled}
              onChange={(periodType) => onSelectPeriod(key, periodType?.value)}
              name={key}
              isViewPage={true}
              className="viewSelectInput view-input"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-label-wrapper">
            <label htmlFor="reports" className="form-label view-label">
              Date Period
            </label>
          </div>
          <div className="form-input-wrapper view-input-wrapper">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              selectedPeriodType={periodType}
              onDateRangeClick={handleChangeCustomDateRanges}
              periodTypeKey={key}
            />
          </div>
        </div>
      </>
    );
  };

  const handleSearch = (e) => {
    let { matchedViewsList } = inputValues;
    const { name, value } = e.target;
    const filteredViewsList = matchedViewsList?.filter(({ viewName }) =>
      viewName.toLowerCase().includes(value.toLowerCase())
    );

    setInputValues({
      ...inputValues,
      viewsSearchList: filteredViewsList?.length > 0 ? filteredViewsList : [],
      [name]: value,
    });
  };

  const clearSearch = () => {
    const { matchedViewsList } = inputValues;
    setInputValues({
      ...inputValues,
      searchView: "",
      viewsSearchList: matchedViewsList?.length > 0 ? matchedViewsList : [],
    });
  };

  const handleSelectViews = (ele) => {
    const updatedViewsList = inputValues.matchedViewsList.map((obj) => {
      if (ele.viewId === obj.viewId) {
        obj.isSelected = !obj.isSelected;
      }
      return obj;
    });
    setInputValues({
      ...inputValues,
      matchedViewsList: updatedViewsList,
      selectedViewIdsList: updatedViewsList
        ?.filter((ele) => ele.isSelected === true)
        ?.map(({ viewId }) => viewId),
    });
  };

  const {
    isDynamicDateRange,
    primaryPeriodType,
    primaryStartDate,
    primaryEndDate,
    secondaryStartDate,
    secondaryEndDate,
    secondaryPeriodType,
    searchViewsStartDate,
    searchViewsEndDate,
    searchViewsPeriodType,
    searchView,
    viewsSearchList,
    matchedViewsList,
    selectedViewIdsList,
  } = inputValues;

  let findPrepostView =
    selectedScreen === 'reports' 
      ? data[0]?.viewScreenTypes?.find((ele) => ele === "PREPOST")
      : data
          ?.filter((ele) => selectedViewIdsList?.includes(ele.viewId))
          ?.find((ele) => ele.screenType === "PREPOST");

  let isDisabled = selectedViewIdsList?.length > 0 ? false : true;
  let isSubmitButtonDisabled = (selectedViewIdsList?.length === 0 || primaryStartDate === null || primaryEndDate === null)
  return (
    <>
      <Modal
        ref={modalRef}
        open={isOpen}
        onClose={() => {
          closeModal();
          handleViewIds();
        }}
        initialFocusRef={modalRef}
        center
        closeIcon={<span style={{ display: "none" }} />}
        classNames={{
          root: "views_detail_modal_root",
          modal: "warning-modal views-modal views_detail_modal",
          modalAnimationIn: "react-responsive-modal-from-right-in",
          modalAnimationOut: "react-responsive-modal-from-right-out",
        }}
      >
        <div className="modalHeader">
          <div className="modalHeaderContainer">
            <div className="roundedLogo viewModalLogo">
              <img
                src={addIcon}
                alt=""
                className="warningIcon"
                style={{ color: "#7f56d9" }}
              />
            </div>
            <div className="modalHeaderContent">
              <div className="modalTitle">Edit Date Period</div>
              <div className="modalSubTitle">
                Override the date period across all views
              </div>
            </div>
          </div>
          <RiCloseLine
            className="warningIcon closeIcon"
            onClick={() => {
              closeModal();
              handleViewIds();
            }}
          />
        </div>
        <div className="modalContent">
          <div className="form-container form-container-modalContent">
            <>
              {selectedScreen === "reports" && (
                <>
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <div className="form-label-wrapper">
                      <label
                        htmlFor="reports"
                        className="form-label view-label"
                      >
                        Find Date Period
                      </label>
                    </div>
                    <div className="form-input-wrapper view-input-wrapper ">
                      <div className="content">
                        Find views matching the selected date period within the
                        report.
                      </div>
                    </div>
                  </div>
                  {renderDatePicker(
                    "searchViewsPeriodType",
                    searchViewsPeriodType,
                    searchViewsStartDate,
                    searchViewsEndDate
                  )}
                  <div className="form-group">
                    <label htmlFor="slides" className="form-label view-label">
                      Matched Views
                    </label>
                    <div className="form-input view-input">
                      <img src={searchIcon} className="iconConatiner" alt="" />
                      <input
                        type="text"
                        id="searchView"
                        placeholder="Search for Views"
                        value={searchView}
                        onChange={handleSearch}
                        name="searchView"
                        style={{
                          width: "85%",
                          border: "none",
                          fontSize: "12px",
                          letterSpacing: "0.25px",
                        }}
                      />
                      {searchView && (
                        <RxCrossCircled
                          className="iconConatiner cursor"
                          onClick={clearSearch}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={cx(
                      viewsSearchList?.length > 0 ? "viewsDataList" : ""
                    )}
                  >
                    <div
                      className="viewsSelectListContainer viewsActionsListConatiner"
                      ref={containerRef}
                    >
                      {searchViewsStartDate !== null &&
                      searchViewsEndDate !== null ? (
                        viewsSearchList?.length > 0 ? (
                          viewsSearchList?.map((ele, ind) => {
                            let logoObj = screenIcons.find(
                              ({ screenType }) => screenType === ele.screenType
                            );
                            return (
                              <div
                                className={cx("viewListConatiner", {
                                  active: ele?.isSelected,
                                })}
                                key={ind}
                                onClick={() => handleSelectViews(ele)}
                              >
                                <div className="viewsBox">
                                  <div className="viewsContentBoxConatiner">
                                    <div className="viewModalroundedLogo">
                                      <img
                                        src={logoObj?.logo}
                                        alt=""
                                        className="screenIcon"
                                      />
                                    </div>
                                    <div className="viewListContentContainer">
                                      <div className="header">
                                        {ele.viewName}
                                      </div>
                                      <div className="subHeader">
                                        {ele.module}/{ele.screenType}
                                      </div>
                                      <div className="content">
                                        {!(
                                          ele?.periodType === "CUSTOM_RANGE" ||
                                          ele?.periodType ===
                                            "DYNAMIC_DATE_RANGE"
                                        ) ? (
                                          periodTypes?.find(
                                            ({ name }) =>
                                              ele.periodType
                                                ?.replace(/_/g, " ")
                                                ?.toLowerCase() ===
                                              name?.toLowerCase()
                                          )?.name
                                        ) : (
                                          <>
                                            {DateTime.fromFormat(
                                              ele.startDate,
                                              "yyyy-MM-dd"
                                            ).toFormat("MM/dd/yyyy")}{" "}
                                            -{" "}
                                            {DateTime.fromFormat(
                                              ele.endDate,
                                              "yyyy-MM-dd"
                                            ).toFormat("MM/dd/yyyy")}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="viewCheckBoxContainer">
                                    <input
                                      type="checkbox"
                                      id="checkbox"
                                      checked={ele?.isSelected}
                                      className="viewCheckBox"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : matchedViewsList?.length > 0 ? (
                          <div className="noViewsContainer">
                            <div className="content">
                              No views found related to search.
                            </div>
                          </div>
                        ) : (
                          <div className="noViewsContainer">
                            <div className="content">
                              No views found matching date period.
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="noViewsContainer">
                          <div className="content">
                            Please select the dates to get the views.
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <div className="form-label-wrapper">
                      <label
                        htmlFor="reports"
                        className="form-label view-label"
                      >
                        Replace Date Period
                      </label>
                    </div>
                    <div className="form-input-wrapper view-input-wrapper ">
                      <div className="content">
                        Replace/Update the date period for the views matched
                        above.
                      </div>
                    </div>
                  </div>
                </>
              )}
              {renderDatePicker(
                "primaryPeriodType",
                primaryPeriodType,
                primaryStartDate,
                primaryEndDate,
                isDisabled
              )}
              {findPrepostView && selectedScreen !== "reports" && (
                <>
                  <div className="form-group" style={{ marginTop: "5px" }}>
                    <div className="form-label-wrapper">
                      <label
                        htmlFor="reports"
                        className="form-label view-label"
                      >
                        Secondary Date Period
                      </label>
                    </div>
                    <div className="form-input-wrapper view-input-wrapper ">
                      <div className="content">
                        This report includes views from the ‘Pre/Post’ screen,
                        which features two date ranges. Please select the date
                        range for the “Post” period.
                      </div>
                    </div>
                  </div>
                  {renderDatePicker(
                    "secondaryPeriodType",
                    secondaryPeriodType,
                    secondaryStartDate,
                    secondaryEndDate
                  )}
                </>
              )}
              <div className="checkBoxContainer">
                <input
                  type="checkbox"
                  id="checkbox"
                  disabled={primaryPeriodType !== 1}
                  checked={isDynamicDateRange}
                  className={cx(!isDynamicDateRange && "checkbox")}
                  onChange={handlecheckbox}
                />
                <div className="view-input-warpper-input">
                  <div className="viewFormTitle">Use Dynamic Date Range</div>
                  <div className="viewFormSubTitle">
                    <span style={{ fontWeight: "600" }}>'End Date'</span> will
                    always be relative to the date of viewing.
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div
          className={"form-button-actions"}
          style={{ padding: "0px", width: "auto" }}
        >
          <div
            className="cancelButton"
            onClick={() => {
              closeModal();
              handleViewIds();
            }}
          >
            Cancel
          </div>
          <div
            className={cx("saveButton", {
              disabled: isLoading || isSubmitButtonDisabled,
            })}
            onClick={handleEditViews}
          >
            Save
          </div>
        </div>
      </Modal>
    </>
  );
};
export default EditViewDateSelector;
