import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import closeIcon from "../../assets/icons/icon_views_close.svg";
import "./Views.scss";
import ApiData from "../../utils/Api";
import {
  setReports,
  setViews,
} from "../../reducers/TwoByTwoChart/actionCreators";
import alertIcon from "../../assets/icons/alert-circle.png";

const DeleteViewOrCategory = (props) => {
  let { isOpen, closeModal, selectedData, selectedType, selectedViewIds, handleViewIds, handleShowToaster, onCloseDetailedModal = () => {} ,handleToasterMessage = () => {}} = props;
  
  const modalRef = useRef(null);
  const [deleteAllViews, setDeleteAllViews] = useState(false);

  const handleChange = () => {
    setDeleteAllViews(!deleteAllViews);
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => ({
    reportsListData: state?.twoByTwoChart?.reportsList,
    viewsListData: state?.twoByTwoChart?.viewsList,
  }));

  const handleDelete = async () => {
    let { viewsListData } = data;
    try {
      if (selectedType === "report") {
        let data = await ApiData.deleteReports({
          reportIds: [selectedData[0]?.id],
          deleteAllViews: deleteAllViews,
        });
        dispatch(setReports(data));
        handleToasterMessage({
            type: "success",
            message: "Report has been succesfully deleted.",
        })
      } else {
        let selectedViewsIdsList =
          selectedViewIds?.length > 0
            ? [...selectedViewIds]
            : [selectedData[0]?.viewId];
        await ApiData.deleteViews({ viewIds: selectedViewsIdsList });
        let viewsList = viewsListData?.filter(
          (ele) => !selectedViewsIdsList?.includes(ele.viewId)
        );
        dispatch(setViews([...viewsList]));
        onCloseDetailedModal();
        handleToasterMessage({
          type: "success",
          message: "View has been succesfully deleted.",
      }
          )
      }
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      ref={modalRef}
      open={isOpen}
      onClose={()=>{closeModal();handleViewIds && handleViewIds()}}
      initialFocusRef={modalRef}
      center
      closeIcon={<span style={{ display: "none" }} />}
      classNames={{
        modal: "warning-modal delete-modal",
      }}
    >
      <div className="modalHeader">
        <div className="modalHeaderContainer">
          <div className="roundedLogo viewModalDeleteLogo">
            <img src={alertIcon} className="warningIcon" />
          </div>
          <div className="modalHeaderContent">
            <div className="modalTitle">Are you sure?</div>
            <div className="modalSubTitle">
              The {selectedViewIds?.length > 0 ? "views" : selectedType} will be
              deleted permanently.
            </div>
          </div>
        </div>
        <img
          src={closeIcon}
          className="warningIcon closeIcon"
          onClick={closeModal}
          alt=""
        />
      </div>
      <div className="modalContent">
        <div className="form-container form-container-modalContent">
          {selectedType === "view" ? (
            <div className="buttonConatiner" style={{ display: "flex" }}>
              <div
                className="viewFormTitle deleteContentContatiner"
                style={{ width: "80%" }}
              >
                {selectedViewIds?.length > 0
                  ? selectedViewIds?.length
                  : selectedData?.length}{" "}
                {selectedViewIds?.length > 0 ? "views" : selectedType} selected.
              </div>
              <div className={"form-button-actions"} style={{ width: "auto" }}>
                <div
                  className={"cancelButton"}
                  onClick={()=>{closeModal();handleViewIds && handleViewIds()}}
                  style={{ width: "80px" }}
                >
                  Cancel
                </div>
                <div
                  className={"deleteButton"}
                  onClick={handleDelete}
                  style={{ width: "80px" }}
                >
                  Delete
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="contentContainer deleteContentContainer">
                <div className="view-label delete-label">
                  {" "}
                  Are you sure you want to delete {selectedData[0]?.name}{" "}
                  report?
                </div>
                <div className="checkboxcontainer">
                  <input
                    type="checkbox"
                    checked={deleteAllViews}
                    onChange={handleChange}
                  />
                  <div className="view-label delete-sublabel">
                    Delete views within report
                  </div>
                </div>
              </div>
              <div className={"form-button-actions"}>
                <div className="cancelButton" onClick={closeModal}>
                  Cancel
                </div>
                <div className={"deleteButton"} onClick={handleDelete}>
                  Delete
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default DeleteViewOrCategory;
