// reducers/chatReducer.js
const initialState = {
  messages: [],
  isTyping: false,
  isAssistantResponding: false,
  hasInitializedMessages: false,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MESSAGE":
      const updatedMessages = [...state.messages, action.payload];
      localStorage.setItem("messages", JSON.stringify(updatedMessages));
      return { ...state, messages: [...state.messages, action.payload] };
    case "ADD_BULK_MESSAGE":
      return { ...state, messages:action.payload };
    case "USER_TYPING":
      return { ...state, isTyping: action.payload };
    case "ASSISTANT_RESPONDING":
      return { ...state, isAssistantResponding: action.payload };
    case "DELETE_ALL_MESSAGES":
      window.localStorage.removeItem("messages");
      return { ...state, messages: [] };
    case "SET_HAS_INITIALIZED_MESSAGES":
      return { ...state, hasInitializedMessages: action.payload };
    default:
      return state;
  }
};

export default chatReducer;
