import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {useSelector } from "react-redux";
import Message from "./message";
import MessageLoader from "./messageLoader";
import Feedback from "./feedback";
import { 
  selectMessages,
  selectIsAssistantResponding,
  selectIsTyping
} from "./chatSelectors";

const MessageContent = forwardRef((props, ref) => {
  const messages = useSelector(selectMessages);
  const userTypingConfigurationSetting = false;
  const isUserTyping = useSelector(selectIsTyping);
  const isAssistantResponding = useSelector(selectIsAssistantResponding);

  const feedbackTimeoutRef = useRef(null);
  const feedbackContainerRef = useRef(null);
  const messageEndRef = useRef(null);
  const didUserInvokedChatRef = useRef(false);

  const previousTypes = [];

  const getPreviousMsgType = (msgType, index) => {
    previousTypes.push(msgType);
    return index === 0 ? "" : previousTypes[index - 1];
  };

  const scrollToBottom = () => {
    const container = messageEndRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
   };

  // Expose scrollToBottom through the ref
  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }));

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    const timer = setTimeout(()=>{
      scrollToBottom()
    },100)
    return ()=> clearTimeout(timer);
  }, [messages,isAssistantResponding]);

  // related to the feedback container 
  // useEffect(() => {
  //   if (!didUserInvokedChatRef.current) {
  //     didUserInvokedChatRef.current = true;
  //     return;
  //   }
  //   if (isAssistantResponding) {
  //     return;
  //   }
  //   feedbackTimeoutRef.current = setTimeout(() => {
  //     if (feedbackContainerRef.current) {
  //       feedbackContainerRef.current.style.display = "block";
  //     }
  //   }, 15000);

  //   if (isUserTyping) {
  //     clearTimeout(feedbackTimeoutRef.current);
  //     if (feedbackContainerRef.current) {
  //       feedbackContainerRef.current.style.display = "none";
  //     }
  //   }

  //   return () => clearTimeout(feedbackTimeoutRef.current);
  // }, [isAssistantResponding, isUserTyping]);

  return (
    <div className="card-content">
      <ul className="msg-container" ref={messageEndRef}>
        {messages.map((msg, index) => (
          <Message
            key={index}
            type={msg.type}
            text={msg.text}
            previousType={getPreviousMsgType(msg.type, index)}
          />
        ))}
        {isAssistantResponding && (
          <MessageLoader type="Assistant" isVisible={isAssistantResponding} />
        )}
        {userTypingConfigurationSetting && isUserTyping && (
          <MessageLoader type="You" />
        )}
        {/* Feedback changes  */}
        {/* <div ref={feedbackContainerRef} style={{ display: "none" }}>
          <Feedback />
        </div> */}
      </ul>
    </div>
  );
});

export default MessageContent;
