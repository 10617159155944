import { Button } from "@material-ui/core";
import Tooltip from "./tooltip";
import { CloseOutlined, ChatBubbleOutlineRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  addBulkMessage,
  userTyping,
  assistantResponding,
  setHasInitializedMessages,
} from "../../../../reducers/actions/actions";
import { 
  selectHasInitializedMessages, 
} from "./chatSelectors";
import { initiateSession } from "./sessionManager";
import "./chat.scss";
import MessageContent from "./messageContent";
import MessageInput from "./messageInput";

const Chat = () => {
  const [isDataReady, setIsDataReady] = useState(false);
  const dispatch = useDispatch();
  const hasInitializedMessages = useSelector(selectHasInitializedMessages);

  // Check if localStorage data is ready
  useEffect(() => {
    const checkLocalStorageData = () => {
      const storedBrands = localStorage.getItem("globalBrands");
      if (storedBrands) {
        setIsDataReady(true);
      }
    };
    checkLocalStorageData();
    const interval = setInterval(checkLocalStorageData, 1000);
    return () => clearInterval(interval);
  }, []);

  // Initiate session only when localStorage data is ready
  useEffect(() => {
    const sessionInitialized = localStorage.getItem("sessionInitialized");
    if (isDataReady && !sessionInitialized) {
      initiateSession();
    }
  }, [isDataReady]);

  const toggleToolTipOpenHandler = () => {
    const visibility = document.getElementById("message-content-container");
    if (visibility.style.visibility === "visible") {
      visibility.style.visibility = "hidden";
      visibility.style.opacity = 0;
    } else {
      visibility.style.visibility = "visible";
      visibility.style.opacity = 1;
  
      const storedMessages = JSON.parse(localStorage.getItem("messages"));
      if (storedMessages?.length > 0) {
        dispatch(addBulkMessage(storedMessages));
        dispatch(setHasInitializedMessages(true));
      } else if (!hasInitializedMessages) {
        dispatch(setHasInitializedMessages(true));
        addInitialMessages();
      }
    }
  };
  

  const addInitialMessages = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const firstName = userInfo?.firstName || "there";
    const lastName = userInfo?.lastName || "";

    // to keep the user more engaging added the seasonalgreeting message
    // TODO : Make it as configurable through the env or something else
    const getSeasonalGreeting = () => {
      const month = new Date().getMonth();
      const monthGreetings = {
        0: `Hi ${firstName}! ❄️ January is a fresh start, and I’m here to make it even better. Let’s dive in—what can I help with today?`,
        1: `Hello ${firstName}! ❤️ February is the month of love, and I’m here to assist with care. How can I make your day smoother?`,
        2: `Hi ${firstName}! 🌸 March brings blossoms and possibilities. What would you like to tackle today?`,
        3: `Hello ${firstName}! 🌼 April showers may come, but I’ll make sure your tasks are sunny. What’s on your mind?`,
        4: `Hi ${firstName}! ☀️ May is perfect for new ideas and bright days. Let’s make it amazing—how can I assist?`,
        5: `Hello ${firstName}! 🌊 June is the perfect time for making waves. What’s your next big splash?`,
        6: `Hi ${firstName}! 🏖️ July vibes are all about relaxing and achieving. Let me know how I can help you!`,
        7: `Hello ${firstName}! 🍉 August is full of summer sweetness. Let’s make your day just as sweet. How can I assist?`,
        8: `Hi ${firstName}! 🍂 September brings crisp air and fresh goals. What’s the first thing on your list?`,
        9: `Hello ${firstName}! 🎃 October is spooky good for productivity. Let’s conquer your tasks—what’s first?`,
        10: `Hi ${firstName}! 🦃 November is a time for gratitude, and I’m thankful to help. What’s on your mind today?`,
        11: `Hello ${firstName}! 🎅 December’s festive spirit is here. Let’s wrap up your goals like holiday gifts! What can I help with?`,
      };
      return (
        monthGreetings[month] ||
        `Hi ${firstName} ${lastName}! 😊 How can I make your day special today?`
      );
    };

    const initialMessages = [
      getSeasonalGreeting(),
      "Let me know where you’d like to navigate...",
    ];
  
    dispatch(assistantResponding(true));
    for (const message of initialMessages) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(addMessage({ type: "Assistant", text: message }));
    }
    dispatch(assistantResponding(false));
  };

  const ChatBox = () => (
    <div className="card-container chat-container">
      <div className="card-header">
        <div className="card-title">
          <span>
            HQB Assistant <span>(v0.2.0)</span>
          </span>
          <br />
          <span>How can I assist you?</span>
        </div>
        <div className="close-icon">
          <CloseOutlined fontSize="small" onClick={toggleToolTipOpenHandler} />
        </div>
      </div>
      <MessageContent />
      <MessageInput />
    </div>
  );

  return (
    <Tooltip
      title={<ChatBox />}
      toggleToolTipOpenHandler={toggleToolTipOpenHandler}
    >
      <Button variant="contained" className="tooltip-message-button">
        <ChatBubbleOutlineRounded />
      </Button>
    </Tooltip>
  );
};

export default Chat;
