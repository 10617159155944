import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from "classnames";
import moment from 'moment';
import './BrandSummaryHomeComponent.scss'
import LoadingLayer from '../widgets/Shared/LoadingLayer';
import NoDataBox from '../widgets/Shared/NoDataBox';
import initData from '../../utils/initData';
import ApiData from '../../utils/Api';
import EmotionalCard from '../widgets/ChartComponents/EmotionalCard';
import FunnelChartCard from '../widgets/ChartComponents/FunnelChartCard';
import LineChartCard from '../widgets/ChartComponents/LineChart';
import DonutChart from '../widgets/ChartComponents/DonutChart';
import upIcon from '../../assets/icons/icon_positive.svg';
import downIcon from '../../assets/icons/icon_negative.svg';
import transformHigherLowerThan from '../../utils/transformHigherLowerThan';
import createStatTestingMessage, { getToExclude, getStatTestingIcon } from '../../utils/createStatTestingMessage';
import {
    setChartData as setTrendsData,
    clearAllData as clearAllTrendsData,
} from '../../reducers/Trends/actionCreators';
import dateTransform from '../../utils/dateTransform';
import { validationsForHSSMarketLabels } from '../../utils/common';

class BrandSummaryHomeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readyShow: false,
            loading: true,
            equityData: [],
            funnelData: [],
            emotionData: false,
            advertisingData: false,
            trendsData: []
        }
        this.renderHeader = this.renderHeader.bind(this);
        this.bcolors = initData.getColors().map(color => color[0]);
    }

    componentDidUpdate(prevProps) {
        if (this.props?.statTestingEnabled !== prevProps?.statTestingEnabled) {
            this.setState({loading: true}, () => {
                this.getListData(true);
            })
        }
    }
    componentWillUnmount() {
        const { clearAllTrendsData } = this.props;
        clearAllTrendsData();
    }

    componentWillUnmount() {
        const { clearAllTrendsData } = this.props;
        clearAllTrendsData();
    }

    getBrand = () => {
        let { brands, selectedTab } = this.props;
        const brandColor = this.bcolors[selectedTab%10];
        return {brand: brands[selectedTab], brandColor};
    }
   
    getSummaryData = async(needToCallDueToStatTestingToggle = false) => {
        let { brands, interval, selectedDate, statTestingEnabled, selectedCampaign, selectedBrand, useExposed, intervalValue, periodValue, summaryAPIRestrict, selectModeInterval, page } = this.props;
         let optionModes = initData.getModes();
        let selectedMode = optionModes.filter(obj=> obj.id === useExposed)[0]?.name?.toUpperCase().replace(/ /g, "");
        let trendPayload={
            marketId:selectedCampaign.marketId,
            brandIds:[brands[selectedBrand]?.id],
            fromDate:selectedDate.startdate,
            toDate:selectedDate.enddate,
            trend: {intervalUnit: interval},
            audienceType: selectedMode,
            module: initData.getModuleType(page),
            statTesting: statTestingEnabled
        }
        let brandIds = brands?.filter(obj=> Number(obj?.id) !== Number(brands[selectedBrand]?.id)).map(obj=>obj?.id)
        brandIds.unshift(brands[selectedBrand]?.id)
        let payload={
            marketId:selectedCampaign.marketId,
            brandIds:brandIds,
            fromDate:selectedDate.startdate,
            toDate:selectedDate.enddate,
            audienceType: selectedMode,
            statTesting: statTestingEnabled,
            module: initData.getModuleType(page)
        }
      if(periodValue || intervalValue || summaryAPIRestrict || selectModeInterval || needToCallDueToStatTestingToggle){  
        await Promise.allSettled([ApiData.getSummaryData(payload)]).then(([equityData]) => {
            if(equityData.status === 'fulfilled') {
                this.props.onPeriodChange(false)
                this.props.onIntervalChange(false)
                this.props.onSelectModeChange(false)
                this.props.onMarketBrandChange(false)
                this.getEquityData(equityData.value.equityList);
                this.getConversionData(equityData.value.conversion);
                this.getEmotionalData(equityData.value.emotional);
                this.getAdvertisingData(equityData.value.advertising);
            } else if(equityData.status === 'rejected') {
                console.log("unable to get the equity data.");
            }
        }).catch((error) => {
            console.log("see err: ", error);
        })
    }
       await Promise.allSettled([ApiData.getSummaryTrendsData(trendPayload)]).then(([trendsData]) => {
            if(trendsData.status === 'fulfilled') {
                this.getTrendsData(trendsData.value)
            } else if(trendsData.status === 'rejected') {
                console.log("unable to get the trends data.");
            }
        }).catch((error) => {
            console.log("see err: ", error);
        })
    }
    
    getEquityData = (equityList) => {
        let { brands } = this.props;
        const equityData = equityList.map((item, index) => {
            const brandColor = this.bcolors[index%10];
            const data = {
                value: Number(item?.equity?item?.equity?.toFixed(1):0),
                upDown: Number(item?.equityDiff?.toFixed(1)),
                pool: Number(item?.pool?.toFixed(2)),
                data: [
                    {label: "Momentum", value: Number(item?.netMomentum?.toFixed(1)), diff: Number(item?.netMomentumDiff?.toFixed(1))},
                    {label: "Consideration", value: Number(item?.consideration?.toFixed(1)), diff: Number(item?.considerationDiff?.toFixed(1))},
                    {label: "Quality", value: Number(item?.highQuality?.toFixed(1)), diff: Number(item?.highQualityDiff?.toFixed(1))},
                    {label: "Familiarity", value: Number(item?.familiarity?.toFixed(1)), diff: Number(item?.familiarityDiff?.toFixed(1))}
                ]
            };
            return {
                brand: brands.find(elem => elem.name === item.inputName),
                color: brandColor,
                data: data,
                higherThan: transformHigherLowerThan(item?.higherThan),
                lowerThan: transformHigherLowerThan(item?.lowerThan),
            }
        });
        equityData.sort((x, y) => (y.data?.value - x.data?.value));
        this.setState({
            equityData,
        })
    }

    getConversionData = (result) => {
        const { brand, brandColor } = this.getBrand();
        const ary = [result];

        const conversionData = ary.map((item) => {
            const data = [
                {label: 'Awareness', value: Number(item?.awareness?.toFixed(1)), percentage: Number(item?.awarenessPercent?.toFixed(1)), key: 'awareness'},
                {label: 'Familiarity', value: Number(item?.familiarity?.toFixed(1)), percentage: Number(item?.familiarityPercent?.toFixed(1)), key: 'familiarity'},
                {label: 'Trial', value: Number(item?.trial?.toFixed(1)), percentage: Number(item?.trialPercent?.toFixed(1)), key:'trial'},
                {label: 'Usage', value: Number(item?.usage?.toFixed(1)), percentage: Number(item?.usagePercent?.toFixed(1)), key:'usage'},
                {label: 'Recommend', value: Number(item?.recommend?.toFixed(1)), percentage: Number(item?.recommendPercent?.toFixed(1)), key:'recommend'}
            ]
            data.sort((a,b) => (b.value - a.value))
            return {
                brand: brand,
                color: brandColor,
                data: { pool: item?.pool?.toFixed(0), data: data },
                lowerThan: item?.lowerThan,
                higherThan: item?.higherThan,
            }
        });
        this.setState({
            funnelData: conversionData
        })
    }
    getEmotionalData = (emotionalData) => {
        const {selectedCampaign} = this.props;
        const { brand, brandColor } = this.getBrand();
        const data = validationsForHSSMarketLabels(emotionalData, selectedCampaign?.countryCode);

        const emotionData = {
            brand: brand,
            color: brandColor,
            data: { pool: emotionalData?.pool, data: data },
        }
        this.setState({
            emotionData
        })
    }
    getAdvertisingData = (advData) => {
        const {selectedCampaign} = this.props;
        const { brand, brandColor } = this.getBrand();
        const data = validationsForHSSMarketLabels(advData, selectedCampaign?.countryCode);

        const advertisingData = {
            brand: brand,
            color: brandColor,
            data: { pool: advData?.pool, data: data },
        };
        this.setState({
            advertisingData
        })
    };

    getTrendsData = async(trendsList) => {
        let { setTrendsData, interval } = this.props;
        const { brand, brandColor } = this.getBrand();
        let data=[]
        trendsList.forEach((item)=>{
            data.push({
                date: item.date,
                pool: Number(item.pool?.toFixed(0)),
                total: Number(item.equity?.toFixed(1)),
                momentum: Number(item.netMomentum?.toFixed(1)),
                consideration: Number(item.consideration?.toFixed(1)),
                quality: Number(item.highQuality?.toFixed(1)),
                familiarity: Number(item.familiarity?.toFixed(1)),
                lowerThan: item.lowerThan,
                higherThan: item.higherThan,
            });
        })
        let trendsData = [{
            tab: 4,
            stab: 0,
            brand: brand,
            color: brandColor,
            brandShow: true, 
            data: data
         }]
        setTrendsData(dateTransform(trendsData,interval)); 
        this.setState({
            trendsData:trendsData
        })
    }

    getListData = async(needToCallDueToStatTestingToggle = false) => {
        await this.getSummaryData(needToCallDueToStatTestingToggle);
        this.setState({
            loading: false,
            readyShow: true
        });
    }

    renderHeader(title, next, ssize) {
        return (
            <div className={cx("chartHeaderContainer", {"equityTitle": next === 0})}>
                <div className="titleContainer">
                    <div className="headerLabel" onClick={()=>this.props.onClickNextFunction(next, 'summary')}>{title}</div>
                    {Number(ssize) >0 &&<div className="sSize">n={ssize && ssize.toLocaleString('en-En')}</div>}
                </div>
                {title === "Advertising" &&moment(this.props.selectedDate.startdate).isBefore('2022-09-21') &&
                  <div className='advertisingWarning'>(Data for this module is only available from September 21, 2022)</div>
                }
            </div>
        )
    }

    render() {
        const { readyShow, loading, equityData, funnelData, emotionData, advertisingData, trendsData } = this.state;
        const { selectedTab, brands, selectedDate, interval, chartsIdPrefix, isExportComponent, statTestingEnabled, page, selectedCampaign } = this.props;
        const brandName = brands[selectedTab]?.displayName ? brands[selectedTab]?.displayName : brands[selectedTab]?.name;
        const brandId = brands[selectedTab]?.id;
        const bData = !!equityData && equityData.filter(b => { return b.brand?.id === brandId })[0];
        const cperiod = selectedDate.selectedPeriod>1?initData.getPeriodOptions()?.find(({id})=> id === selectedDate.selectedPeriod)?.name:selectedDate.startdate+' to '+selectedDate.enddate;
        const adData = advertisingData?.data?.data?.filter(item => item.label !== "advertisingRecall");
        return (
            <div className="mainContainer">
                {!isExportComponent && loading && <LoadingLayer />}
                {readyShow &&
                    <div>
                        <div className="middleContainer">
                            <div className="middleCardContainer">
                                {equityData && readyShow === true &&
                                    <div className="equityContainer">
                                        {equityData.length>0 && bData?.data.value > 0 && readyShow === true &&
                                            <div>
                                                {this.renderHeader("Brand Equity", 0, Math.round(bData?.data?.pool))}
                                                {bData?.data.value > 0 &&
                                                <div className="donutContainer">
                                                    <DonutChart
                                                        colors={initData.getDonutColors()}
                                                        colorIndex={{Momentum:4, Consideration:3, Quality:2, Familiarity:1}}
                                                        data={bData.data.data}
                                                        title={"EQUITY"}
                                                        number={bData.data.value}
                                                        upDown={bData.data.upDown}
                                                        brand={brandName}
                                                        tag={"eq" + selectedTab}
                                                        size={200}
                                                        cperiod={cperiod}
                                                        statTestingEnabled={statTestingEnabled === "POP" || statTestingEnabled === "YOY"}
                                                        higherThan={bData.higherThan}
                                                        lowerThan={bData.lowerThan}
                                                        page={page}
                                                        selectedDate={selectedDate}
                                                    />
                                                </div>}
                                                {bData?.data.value > 0 && <div className="listContainer">
                                                {equityData.length > 0 && equityData.map((item, index) => {
                                                    const {
                                                        brand: { name },
                                                        higherThan,
                                                        lowerThan,
                                                    } = item;
                                                    const higher = get(higherThan, 'Equity', []);
                                                    const lower = get(lowerThan, 'Equity', []);
                                                    const toExclude = getToExclude(DonutChart.chartName);
                                                    const statTestingIcon = getStatTestingIcon(higher, lower, toExclude, [upIcon, downIcon]);
                                                    const statTestingMessage = createStatTestingMessage({
                                                        entity: name,
                                                        toExclude,
                                                        higherThan: higher,
                                                        lowerThan: lower,
                                                        period: cperiod,
                                                        asComponent: true,
                                                        startDate: selectedDate?.startdate,
                                                        endDate: selectedDate?.enddate
                                                    });

                                                    return (
                                                        <div className={cx("itemContainer", {itemBgColor: index % 2 !== 0})} key={index + '_' + item.brand.name} >
                                                            <div className={cx("itemTitle", {"current": item.brand.id === brandId})}>
                                                                {item.brand.displayName ? item.brand.displayName : item.brand.name}
                                                            </div>
                                                            <div className="itemData">
                                                                <div className="figure">{item.data.value?item.data.value?.toFixed(1):0}</div>
                                                                {
                                                                    <div className="upDownIcon withQText">
                                                                        {(statTestingEnabled === "POP" || statTestingEnabled === "YOY") && statTestingIcon &&
                                                                            <img src={statTestingIcon} className="icon" alt="" />
                                                                        }
                                                                        {(statTestingEnabled === "POP" || statTestingEnabled === "YOY") && statTestingMessage &&
                                                                            <span dangerouslySetInnerHTML={statTestingMessage()} />
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                }
                                                </div>}
                                            </div>
                                        }
                                        {bData === undefined && 
                                            <div>
                                                {this.renderHeader("Brand Equity", 0, Math.round(bData?.data?.pool))}
                                                <NoDataBox />
                                            </div>
                                        }
                                    </div>
                                }
                                {trendsData && trendsData.length > 0 && readyShow === true &&
                                    <div className="trendsContainer">
                                            <LineChartCard
                                                interval={interval}
                                                noEditable = {true}
                                                stab = {0}
                                                selectedOption = "total"
                                                chartsIdPrefix={chartsIdPrefix}
                                                isExportComponent={isExportComponent}
                                                statTestingEnabled={statTestingEnabled === "POP" || statTestingEnabled === "YOY"}
                                                onChangeFunction={this.props.onChangePeriodFunction}
                                                startdate={selectedDate.startdate}
                                                enddate={selectedDate.enddate}
                                                selectedPeriod={selectedDate.selectedPeriod}
                                                dateFilterId = {this.props.dateFilterId}
                                            />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="summaryBottomContainer">
                            <div className="bottomCardContainer">
                                {funnelData && funnelData[0] && readyShow === true &&
                                    <div className="funnelCardContainer">
                                        {this.renderHeader("Conversion", 1)}
                                        <div className="chartContainer">
                                            <FunnelChartCard
                                                key={'cv' + selectedTab}
                                                tag={'cv' + selectedTab}
                                                id={selectedTab}
                                                data={funnelData[0].data.data}
                                                colors = {initData.getColors(selectedTab%10+1)}
                                                brand={funnelData[0].brand?.displayName ? funnelData[0].brand?.displayName : funnelData[0].brand?.name}
                                                logo={funnelData[0].brand?.logo}
                                                brandValue={funnelData[0].data.pool}
                                                hightRatio={0.8}
                                                lowerThan={funnelData[0].lowerThan}
                                                higherThan={funnelData[0].higherThan}
                                                statTestingEnabled={statTestingEnabled === "POP" || statTestingEnabled === "YOY"}
                                                selectedMarket={selectedCampaign?.countryCode}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="bubbleCardsContainer">
                                    {emotionData && readyShow === true &&
                                        <div className="bubbleCardContainer">
                                            {this.renderHeader("Emotional", 2)}
                                                <EmotionalCard
                                                    key={'em' + selectedTab}
                                                    brand={!!emotionData && !!emotionData.brand && (emotionData.brand.displayName ? emotionData.brand.displayName : emotionData.brand.name)}
                                                    logo={!!emotionData && !!emotionData.brand && emotionData.brand.logo}
                                                    brandValue={!!emotionData && !!emotionData.data && emotionData.data.pool}
                                                    data={!!emotionData && !!emotionData.data.data && emotionData.data.data}
                                                    colors = {initData.getColors(selectedTab%10+1)}
                                                    hideBars={true}
                                                />
                                        </div>
                                    }
                                    {advertisingData && readyShow === true &&
                                        <div className="bubbleCardContainer">
                                            {this.renderHeader("Advertising", 3)}
                                                <div>
                                                    {adData?.length>0 && <div className={moment(this.props.selectedDate.startdate).isBefore('2022-09-21')?"categoryWarningText peopleValue": "peopleValue categoryText"}>Ad Recall<div className="recallValue">{advertisingData?.data?.data?.filter(item => item.label === "advertisingRecall")[0]?.value}%</div></div>}
                                                    <EmotionalCard
                                                        key={'em' + selectedTab}
                                                        brand={advertisingData?.brand?.displayName || advertisingData?.brand?.name}
                                                        logo={advertisingData?.brand?.logo}
                                                        brandValue={advertisingData?.data?.pool}
                                                        data={adData}
                                                        colors = {initData.getColors(selectedTab%10+1)}
                                                        hideBars={true}
                                                    />
                                                </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        setTrendsData,
        clearAllTrendsData,
    }, dispatch)
});


export default connect(
    null,
    mapDispatchToProps,
    null,
    { forwardRef: true },
)(BrandSummaryHomeComponent);